export const headCells = [
  //for zero(0) index for Compliance Category
  [{
    id: 'id',
    label: 'Id',
    sortable: false,
    align: 'left',
    numIndex: 0,
    activeIndexOnly:true,
  },
  {
    id: 'title',
    label: 'Title',
    sortable: true,
    align: 'left',
    numIndex: 0,
    activeIndexOnly:true,
  },
  {
    id: 'is_hide',
    label: 'Hide for candidate',
    sortable: true,
    align: 'left',
    numIndex: 0,
    activeIndexOnly:true,
  },
  {
    id: 'is_active',
    label: 'Status',
    sortable: true,
    align: 'left',
    numIndex: 0,
    activeIndexOnly:true,
  },
  {
    id: 'Invoice',
    label: '',
    sortable: false,
    align: 'right',
    numIndex: 0,
    activeIndexOnly:true,
  },],

  
]
