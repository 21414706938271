import axios from "axios";
import { apiClient } from "../../../config/apiClient";
import Config from "../../../config/config";
import {
  CREATE_SUB_CATEGORY_ERROR,
  CREATE_SUB_CATEGORY_REQUEST,
  CREATE_SUB_CATEGORY_SUCCESS,
  DELETE_SUB_CATEGORY_ERROR,
  DELETE_SUB_CATEGORY_REQUEST,
  DELETE_SUB_CATEGORY_SUCCESS,
  HIDE_SUB_CATEGORY_ERROR,
  HIDE_SUB_CATEGORY_REQUEST,
  HIDE_SUB_CATEGORY_SUCCESS,
  GET_SUB_CATEGORY_ERROR,
  GET_SUB_CATEGORY_REQUEST,
  GET_SUB_CATEGORY_SUCCESS,
  UPDATE_SUB_CATEGORY_REQUEST,
  UPDATE_SUB_CATEGORY_SUCCESS,
  UPDATE_SUB_CATEGORY_ERROR,
  GET_SUB_CATEGORY_BY_ID_REQUEST,
} from "../actiontypes";
import { notificationFail, notificationSuccess } from "../notificationMsg";

export const getSubCategory = (column_name="",type="") => {
  const loggedInUser = localStorage.getItem("token").replace(/['"]+/g, "");
  return async (dispatch) => {
    dispatch(getSubCategoryRequest());
    await axios
      .get(`${Config.API_URL}api/organization/get-subcategories?column_name=${column_name}&type=${type}`, {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${loggedInUser}`,
        },
      })
      .then((response) => {
        const data = response?.data;
        if (data.status === true) {
          dispatch(getSubCategorySuccess(data));
        } else {
          dispatch(getSubCategorySuccess([]));
          dispatch(getSubCategoryError(data));
        }
      })
      .catch((error) => {
        dispatch(getSubCategoryError(error));
      });
  };
};

export const getSubCategoryRequest = () => {
  return {
    type: GET_SUB_CATEGORY_REQUEST,
  };
};
export const getSubCategorySuccess = (data) => {
  return {
    type: GET_SUB_CATEGORY_SUCCESS,
    payload: data,
  };
};
export const getSubCategoryError = (error) => {
  return {
    type: GET_SUB_CATEGORY_ERROR,
    payload: error,
  };
};

// --------------------------------
export const getSubCategoryById = (id, result) => {
  return async (dispatch) => {
    dispatch(getSubCategoryByIdRequest());
    await apiClient(true)
      .get(`api/organization/get-subcategory/${id}`)
      .then((response) => {
        result(response?.data);
      })
      .catch((error) => {
        result(error);
      });
  };
};

export const getSubCategoryByIdRequest = () => {
  return {
    type: GET_SUB_CATEGORY_BY_ID_REQUEST,
  };
};
// --------------------------------

export const createSubCategory = (data) => {
  const loggedInUser = localStorage.getItem("token").replace(/['"]+/g, "");
  return async (dispatch) => {
    dispatch(createSubCategoryRequest());
    await axios
      .post(`${Config.API_URL}api/organization/add-subcategory`, data, {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${loggedInUser}`,
        },
      })
      .then((response) => {
        const data = response?.data;
        if (data.status === true) {
          dispatch(createSubCategorySuccess(data));
          setTimeout(() => {
            dispatch(getSubCategory());
          }, 2000);
        } else {
          dispatch(createSubCategoryError(data));
        }
      })
      .catch((error) => {
        dispatch(createSubCategoryError(error));
      });
  };
};

export const createSubCategoryRequest = () => {
  return {
    type: CREATE_SUB_CATEGORY_REQUEST,
  };
};
export const createSubCategorySuccess = (data) => {
  return {
    type: CREATE_SUB_CATEGORY_SUCCESS,
    payload: data,
  };
};
export const createSubCategoryError = (error) => {
  return {
    type: CREATE_SUB_CATEGORY_ERROR,
    payload: error,
  };
};

// -------------------------------------
export const hideSubCategory = (sub_category_id) => {
  const loggedInUser = localStorage.getItem("token").replace(/['"]+/g, "");
  return async (dispatch) => {
    dispatch(hideSubCategoryRequest());
    await axios
      .get(`${Config.API_URL}api/organization/hide-show-subcategory/${sub_category_id}`, {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${loggedInUser}`,
        },
      })
      .then((response) => {
        const data = response?.data;
        if (data.status === true) {
          dispatch(hideSubCategorySuccess(data));
          dispatch(notificationSuccess(data?.message));
          setTimeout(() => {
            dispatch(getSubCategory());
          }, 2000);
        } else {
          dispatch(hideSubCategoryError(data?.message));
          dispatch(notificationFail(data?.message));
        }
      })
      .catch((error) => {
        dispatch(hideSubCategoryError(error.response?.data));
        dispatch(notificationFail(error.response?.data));
      });
  };
};

export const deleteSubCategory = (sub_category_id) => {
  const loggedInUser = localStorage.getItem("token").replace(/['"]+/g, "");
  return async (dispatch) => {
    dispatch(deleteSubCategoryRequest());
    await axios
      .delete(`${Config.API_URL}api/organization/delete-subcategory/${sub_category_id}`, {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${loggedInUser}`,
        },
      })
      .then((response) => {
        const data = response?.data;
        if (data.status === true) {
          dispatch(deleteSubCategorySuccess(data));
          dispatch(notificationSuccess(data?.message));
          setTimeout(() => {
            dispatch(getSubCategory());
          }, 2000);
        } else {
          dispatch(deleteSubCategoryError(data?.message));
          dispatch(notificationFail(data?.message));
        }
      })
      .catch((error) => {
        dispatch(deleteSubCategoryError(error.response?.data));
        dispatch(notificationFail(error.response?.data));
      });
  };
};

export const deleteSubCategoryRequest = () => {
  return {
    type: DELETE_SUB_CATEGORY_REQUEST,
  };
};
export const deleteSubCategorySuccess = (data) => {
  return {
    type: DELETE_SUB_CATEGORY_SUCCESS,
    payload: data,
  };
};
export const deleteSubCategoryError = (error) => {
  return {
    type: DELETE_SUB_CATEGORY_ERROR,
    payload: error,
  };
};

export const hideSubCategoryRequest = () => {
  return {
    type: HIDE_SUB_CATEGORY_REQUEST,
  };
};
export const hideSubCategorySuccess = (data) => {
  return {
    type: HIDE_SUB_CATEGORY_SUCCESS,
    payload: data,
  };
};
export const hideSubCategoryError = (error) => {
  return {
    type: HIDE_SUB_CATEGORY_ERROR,
    payload: error,
  };
};

// --------------------------------
export const updateSubCategory = (data) => {
  const loggedInUser = localStorage.getItem("token").replace(/['"]+/g, "");
  return async (dispatch) => {
    dispatch(updateSubCategoryRequest());
    await axios
      .post(`${Config.API_URL}api/organization/edit-subcategory`, data, {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${loggedInUser}`,
        },
      })
      .then((response) => {
        const data = response?.data;
        if (data.status === true) {
          dispatch(updateSubCategorySuccess(data));
          setTimeout(() => {
            dispatch(getSubCategory());
          }, 2000);
        } else {
          dispatch(updateSubCategoryError(data));
        }
      })
      .catch((error) => {
        dispatch(updateSubCategoryError(error));
      });
  };
};

export const updateSubCategoryRequest = () => {
  return {
    type: UPDATE_SUB_CATEGORY_REQUEST,
  };
};
export const updateSubCategorySuccess = (data) => {
  return {
    type: UPDATE_SUB_CATEGORY_SUCCESS,
    payload: data,
  };
};
export const updateSubCategoryError = (error) => {
  return {
    type: UPDATE_SUB_CATEGORY_ERROR,
    payload: error,
  };
};
